<template>
    <div class="container" id="container">
        <div class="bl_header">
            <h1>住宅消費エネルギー用途分解プログラム 計算結果</h1>
        </div>
        <div class="p-1">
            <div class="tab-content bl_white">
                <p class="error" v-if="errors.length != 0">
                    {{errors}}
                </p>
                <div
                    v-for="analysis in analysisList"
                    :key="analysis.calorificValue"
                    class="bl_graphs">
                    <h3>{{analysis.calorificValue}}</h3>
                    <div class="flexBox">
                        <div class="bl_ChartWrapper">
                            <canvas :id="'outputChart' + analysis.calorificValue"/>
                        </div>
                        <div class="form-range-box">
                            <h4>調整係数</h4>
                            <div class="form-check-wrapper">
                                <div
                                    class="form-check"
                                    v-for="(name, index) in checkboxes"
                                    :key="'checkboxes' + index"
                                    :class="'form-check__' + index"
                                >
                                    <div
                                        v-show="showRange(name, analysis.calorificValue)"
                                        class="form-range"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :id="'name' + index"
                                            v-model="analysis.use[name]"
                                            @change="calcAndShow(analysis.calorificValue)"
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="analysis.calorificValue + 'name' + index"
                                        >
                                            {{ labelNames[name] }}
                                        </label>
                                        <p>
                                            <span class="nonbreakText">現在の値：</span>
                                            <span class="nonbreakText"> {{analysis.coeff[name]}}</span></p>
                                        <input
                                            class="form-control"
                                            type="range"
                                            step="0.1"
                                            max="2"
                                            min="0"
                                            :id="analysis.calorificValue + 'name' + index + '_coeff'"
                                            v-model="analysis.coeff[name]"
                                            :disabled="name == 'hotWaterSupply'"
                                            @change="calcAndShow(analysis.calorificValue)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :id="'outputTable' + analysis.calorificValue" class="outputTable"/>
               </div>
               <div class="bl_graphs">
                    <h3>合計</h3>
                    <div class="flexBox">
                        <div class="bl_ChartWrapper">
                            <canvas id="outputChart合計"/>
                        </div>
                    </div>
                    <div id="outputTable合計" class="outputTable"/>
               </div>
                <button
                    @click="backToAnalysis"
                    type="button"
                    class="btn btn_back"
                >
                    用途分解の計算へ戻る
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'
export default {
    mounted(){
        // 解析結果を表示する
        this.showResult()
    },
    computed:{
        ...mapGetters(['data']),
        ...mapComputedProperties([
        'errors',
        // 解析リスト
        'analysisList',
        'checkboxes',
        'labelNames'
        ]),
    },
    methods:{
        ...mapActions(['update', 'calcAndShow', 'showResult']),
        showRange(name, calorificValue) {
            return  name === 'hotWaterSupply' || (name === 'cooking' && calorificValue !== '灯油') || (calorificValue === '電気')
        },
        // 住宅消費エネルギー用途分解プログラム計算画面へ戻る
        backToAnalysis(){
            this.$router.push('form')
        }
    }
}
</script>